<template>
  <section class="cart-page">
    <!-- Cart Items List -->
    <div v-for="item in cartData.items" class="cart-item">
      <div class="item-image">
        <img :src="item.image" alt="Product Image" />
      </div>
      <div class="item-details">
        <h4>{{ cartData.order_id }}</h4>
        <!-- Display Order ID as per API response -->
        <h3>{{ item.product_name }}</h3>
        <p class="variants">
          <span v-for="(variant, index) in item.variants" :key="index">
            {{ variant.variant_type }}: {{ variant.variant_value }}
            <span v-if="index < item.variants.length - 1">, </span>
          </span>
        </p>
        <div class="item-price-quantity">
          <p class="price">
            ${{
              item.variants.find((variant) => variant.variant_type === "Price")
                ?.variant_value || "0"
            }}
          </p>
          <!-- Assuming a static price, replace with dynamic if available -->
          <p class="quantity">x {{ item.quantity }}</p>
        </div>
      </div>
    </div>

    <!-- Order Summary Section -->
    <div class="order-summary">
      <div class="summary-row">
        <span>Subtotal</span>
        <span>${{ subtotal }}</span>
      </div>
      <div class="summary-row">
        <span>Tax ( 13% )</span>
        <span>${{ tax }}</span>
      </div>
      <div class="summary-row">
        <span>Fee</span>
        <span>${{ fee }}</span>
      </div>
      <div class="summary-row total">
        <span>TOTAL</span>
        <span>CAD ${{ parseFloat(this.subtotal) + parseFloat(this.tax) }}</span>
      </div>
      <p class="note">*最终商品价格和运费将会在发货前进行调整。</p>
      <button class="proceed-button" @click="openAddressDialog">Proceed</button>
    </div>

    <!-- Address Selection Dialog -->
    <el-dialog
      title="Select Shipping Address"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
    >
    <ul class="address-list">
        <li
          v-for="address in addresses"
          :key="address.id"
          @click="selectAddress(address)"
          :class="{ selected: selectedAddress?.id === address.id }"
        >
          <p><strong>{{ address.recipient_name }}</strong></p>
          <p>{{ address.address }}</p>
          <p>{{ address.address_extra }}</p>
          <p>{{ address.city }}, {{ address.state }} {{ address.zipcode }}</p>
          <p>{{ address.country }}</p>
          <p>+{{ address.area_code }} {{ address.phone }}</p>
        </li>
      </ul>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="proceedToCheckout">
          Confirm
        </el-button>
      </div>
    </el-dialog>
    <Loader :loaderText="loaderText" :loaderToggle="loaderToggle" />
  </section>
</template>

<script>
import axios from "axios";
import Loader from "./utils/Loader.vue";
export default {
  name: "Cart",
  components: {
    Loader
  },
  data() {
    return {
      cartData: {
        cart_id: null,
        order_id: "",
        items: [],
      },
      subtotal: 0,
      tax: 0, // Assuming a static tax for now
      fee: 0, // Assuming a static fee for now
      dialogVisible: false, // Dialog visibility
      addresses: [], // List of user addresses
      selectedAddress: null, // Selected address
      loaderText: 'Please wait',
      loaderToggle: false,
    };
  },
  computed: {},
  methods: {
    async fetchCart() {
      // Check login status
      if (!this.$store.getters.isLoggedIn) {
        alert("You need to login to search for products.");
        this.$router.replace("/login");
        return;
      }
      this.loaderToggle = true
      try {
        const response = await axios.get(
          "https://a8ha3zk9l3.execute-api.us-east-1.amazonaws.com/Prod/cart",
          {
            headers: { Authorization: `Bearer ${this.$store.state.token}` },
          }
        );
        this.cartData = response.data;
        this.calculateSubtotal();
      } catch (error) {
        console.error("Failed to fetch cart items:", error);
      }
      this.loaderToggle = false
    },

    async fetchAddresses() {
      this.loaderToggle = true
      try {
        const response = await axios.get(
          "https://a8ha3zk9l3.execute-api.us-east-1.amazonaws.com/Prod/addresses/list",
          {
            headers: { Authorization: `Bearer ${this.$store.state.token}` },
          }
        );
        this.addresses = response.data;
      } catch (error) {
        console.error("Failed to fetch addresses:", error);
        alert("Failed to fetch addresses. Please try again later.");
      }
      this.loaderToggle = false
      this.dialogVisible = true;
    },
    openAddressDialog() {
      this.fetchAddresses(); // Fetch addresses when dialog opens
      
    },

    handleClose(){
      this.selectedAddress = null;
      this.dialogVisible = false;
    },

    selectAddress(address) {
      this.selectedAddress = address; // Highlight selected address
    },
    calculateSubtotal() {
      this.subtotal = this.cartData.items.reduce((acc, item) => {
        // Find the "Price" variant in the item's variants
        const priceVariant = item.variants.find(
          (variant) => variant.variant_type === "Price"
        );

        // Convert the price to a number (in case it's a string) and calculate the total for the item
        const itemPrice = priceVariant
          ? parseFloat(priceVariant.variant_value)
          : 0;

        // Add the item's total (price * quantity) to the accumulator
        return acc + itemPrice * item.quantity;
      }, 0);

      this.tax = (0.13 * this.subtotal).toFixed(2);
    },

    async proceedToCheckout() {
      // Logic to proceed to checkout
      if (!this.selectedAddress) {
        alert("Please select an address to proceed.");
        return;
      }

      try {
        await axios.post(
          "https://a8ha3zk9l3.execute-api.us-east-1.amazonaws.com/Prod/order/create",
          { shipping_address: this.selectedAddress }, // Pass selected address
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$store.state.token}`,
            },
          }
        );
        this.dialogVisible = false;
        console.log("proceedToCheckout");
        this.$router.push("/order-success");
      } catch (error) {
        console.error("Failed to create order: ", error);
      }
    },
  },
  mounted() {
    this.fetchCart();
  },
};
</script>

<style scoped>
.cart-page {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  background-color: #f9f9f9;
}

.cart-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  border-bottom: 1px solid #ddd;
}

.item-image {
  width: 80px;
  height: 80px;
  background-color: #eaeaea;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 20px;
}

.item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item-details {
  flex: 1;
}

.item-details h4 {
  font-size: 12px;
  color: #333;
  margin: 0;
  font-weight: bold;
}

.item-details h3 {
  font-size: 16px;
  color: #111;
  margin: 5px 0;
  font-weight: bold;
}

.variants {
  font-size: 14px;
  color: #777;
  margin: 5px 0;
}

.item-price-quantity {
  display: flex;
  align-items: center;
}

.price {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.quantity {
  font-size: 14px;
  margin-left: 20px;
  color: #555;
}

.order-summary {
  background-color: #f3f3f3;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}

.summary-row {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.total {
  font-weight: bold;
  font-size: 18px;
}

.note {
  font-size: 12px;
  color: #777;
  text-align: center;
  margin: 10px 0;
}

.proceed-button {
  background-color: black;
  color: #ffde59;
  padding: 15px;
  border-radius: 25px;
  border: none;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
}

.address-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Adjust columns automatically */
  gap: 20px; /* Space between grid items */
  padding: 0;
  margin: 0;
  list-style: none;
}

.address-list li {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: #f9f9f9;
}

.address-list li:hover {
  background-color: #fef8dc;
}

.address-list li.selected {
  border-color: #ffc107;
  background-color: #fff8e1;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
