<template>
  <div class="bar-container">
    <el-progress class="m-progress-bar" :percentage="showPercent" status="success" :text-inside="true" :color="customColor"></el-progress>
  </div>
</template>

<script>
export default {
name: "mProgress",
  props: {
    percent: {
      type: [Number],
      default: 0,
    },
  },
  data() {
    return {
      customColor: '#ffde59',
      showPercent: 0,
    };
  },
  created() {
    this.countPercent();
  },
  beforeDestroy() {
    this.timer = null;
    this.showPercent = 0;
  },
  methods: {
    /**
     * 开启进度条%比的动画
     */
    countPercent() {
      let that = this;
      this.timer = setInterval(function () {
        that.increase();
      }, 100);
    },
    increase() {
      this.showPercent += 5;
      if (this.showPercent < this.percent) {
        this.showPercent += 5;
      } else {
        this.showPercent = this.percent;
        clearInterval(this.timer);
      }
    },
  },
};
</script>

<style scoped lang="less">
.bar-container {
  width: 100%;
}
/deep/.m-progress-bar .el-progress-bar__inner:before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background-image: repeating-linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.3) 0,
    rgba(255, 255, 255, 0.3) 12.5%,
    transparent 0,
    transparent 25%
  );
  background-size: 40px 40px;
  animation: move 1s linear infinite;
}

@keyframes move {
  from {
    background-position: 0;
  }
  to {
    background-position: 40px 0;
  }
}
</style>
