<template>
  <v-dialog
    v-model="loaderToggle"
    hide-overlay
    persistent
    width="200"
    style="border-radius: 100px;"
  >
    <v-card elevation="1" color="white">
      <v-container style="height: 150px;padding-top: 30px;">
        <v-row
          class="fill-height"
          align="center"
          justify="center"
        >
          <v-col
            class="text-subtitle-1 text-center"
            cols="12"
            style="display: flex; align-items: center; justify-content: center;"
          >
            {{ loaderText }}
          </v-col>
          <v-col cols="12" style="padding-top: 30px;">
            <mProgress :percent="100" />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import mProgress from './Progress.vue';
export default {
  
  name: "Loader",
  components: {
    mProgress
  },
  props: {
    loaderToggle: {
      type: Boolean,
      required: true,
    },
    loaderText: {
      type: String,
      default: "Loading...",
    },
  },
  computed: {
    checkTrigger() {
      return this.loaderToggle;
    },
  },
};
</script>
