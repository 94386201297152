<template>
  <section class="account-details-page">
    <!-- Sidebar Section -->
    <aside class="sidebar">
      <h2>Hello</h2>
      <ul class="sidebar-menu">
        <li
          :class="{ active: $route.name === 'AccountInfo' }"
          @click="changeSection('account-info')"
        >
          Account Details
        </li>
        <li
          :class="{ active: $route.name === 'Purchases' }"
          @click="changeSection('purchases')"
        >
          Purchases
        </li>
        <li
          class="inactive"
          @click.prevent
          title="Coming Soon"
        >
          Warehouse
        </li>
        <li
          class="inactive"
          @click.prevent
          title="Coming Soon"
        >
          Shipments
        </li>
        <li
          :class="{ active: $route.name === 'AddressBook' }"
          @click="changeSection('address-book')"
        >
          Address Book
        </li>
      </ul>
    </aside>

    <!-- Dynamic Content Section -->
    <div class="details-container">
      <router-view />
    </div>
  </section>
</template>



<script>
export default {
  methods: {
    changeSection(section) {
      const targetRoute = `/account-details/${section.toLowerCase()}`;

      if (this.$route.path !== targetRoute) {
        this.$router.push(targetRoute).catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            throw err; // If it's a different error, rethrow it
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.account-details-page {
  display: flex;
  padding-left: 70px;
}

.sidebar {
  width: 20%;
  background-color: white;
}

h2 {
  font-family: "Chunk Five", serif;
  font-size: 24px;
  margin-top: 70px;
  margin-bottom: 40px;
}

.sidebar-menu li {
  padding: 20px 15px;
  cursor: pointer;
  font-size: 16px;
  width: 50%;
}

/* Existing active styles */
.sidebar-menu li.active {
  background-color: #ffde59;
  font-weight: bold;
  border-left: 5px solid black;
}

.sidebar-menu li.inactive {
  position: relative; /* Required for the tooltip */
  color: gray; /* Disabled text color */
  cursor: not-allowed; /* Change cursor */
  opacity: 0.6;
}

.sidebar-menu li.inactive:hover::after {
  content: "Coming Soon";
  position: absolute;
  top: 50%;
  left: 110%; /* Adjust position */
  transform: translateY(-50%);
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.sidebar-menu li.inactive:hover::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 105%;
  transform: translateY(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}


.details-container {
  width: 75%;
  margin-top: 30px;
  margin-left: 15px;
}
</style>
