<template>
  <div class="order-details">
    <h3>Order. {{ orderNo }}</h3>

    <!-- Order Items -->
    <div class="order-item" v-for="item in orderItems" :key="item.product_id">
      <div class="product-image">
        <img :src="item.image || '/placeholder-image.png'" alt="Product Image" />
      </div>
      <div class="product-info">
        <p><strong>{{ item.brandName }}, {{ item.productName }}</strong></p>
        <!-- Variants -->
        <p>
          <span v-for="(variant, index) in item.variants" :key="index">
            {{ variant.variant_type }}: {{ variant.variant_value }}
            <span v-if="index < item.variants.length - 1">, </span>
          </span>
        </p>
      </div>
      <div class="product-price">
        <p><strong>${{ item.price || "30" }}</strong></p>
      </div>
      <div class="product-quantity">
        <p>x{{ item.quantity }}</p>
      </div>
    </div>
    <Loader :loaderText="loaderText" :loaderToggle="loaderToggle" />
  </div>
</template>

<script>
import axios from "axios";
import Loader from "../utils/Loader.vue";

export default {
  name: "OrderDetails",
  components: {
    Loader
  },
  data() {
    return {
      orderNo: "", // Order number from the route or API
      orderItems: [], // List of order items
      brands: {}, // Store the brands as a map for quick lookup
      loaderText: 'Please wait',
      loaderToggle: false,
    };
  },
  async created() {
    this.orderNo = this.$route.params.order_number; // Get the order number from the route
    this.loaderToggle = true
    try {
      // Fetch the brands and create a mapping
      const brandsResponse = await axios.get(
        "https://a8ha3zk9l3.execute-api.us-east-1.amazonaws.com/Prod/brands/list"
      );
      const brandsData = brandsResponse.data;

      // Convert the brands into a map for quick lookup
      this.brands = brandsData.reduce((map, brand) => {
        map[brand.id] = brand.brand_name;
        return map;
      }, {});

      // Fetch the order details
      const orderResponse = await axios.get(
        `https://a8ha3zk9l3.execute-api.us-east-1.amazonaws.com/Prod/order/list?order_number=${this.orderNo}`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.token}`, // Replace with your token logic
          },
        }
      );
      const orderData = orderResponse.data;

      // Map the order items and add the brandName from the brands mapping
      this.orderItems = orderData.items.map((item) => ({
        product_id: item.product_id,
        productName: item.product_name,
        quantity: item.quantity,
        image: item.image,
        brandName: this.brands[item.product_id] || "Unknown Brand", // Match product_id with brand
        variants: item.variants, // Use variants as-is
      }));
      this.loaderToggle = false
    } catch (error) {
      console.error("Error fetching order details:", error);
      this.loaderToggle = false
      alert("Failed to fetch order details. Please try again later.");
    }
  },
};
</script>

<style scoped>
.order-details {
  padding: 20px;
}

h3 {
  font-family: 'Chunk Five', serif;
  font-size: 24px;
  margin-bottom: 20px;
}

.order-item {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
}

.product-image img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin-right: 20px;
}

.product-info {
  flex: 1;
}

.product-price,
.product-quantity {
  font-family: 'Canva Sans', sans-serif;
  font-weight: bold;
  text-align: right;
}

.product-price {
  margin-right: 30px;
}

.product-quantity {
  margin-right: 10px;
}
</style>
