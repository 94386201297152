<template>
  <section class="brand-list-page">
    <!-- Top-left Title -->
    <div class="title-section">
      <h2>Choose your brand... <span>请选择品牌</span></h2>
    </div>

    <!-- Brand List -->
    <div class="brands-container">
      <div
        v-for="brand in brands"
        :key="brand.id"
        :class="[
          'brand-card',
          { selected: selectedBrand === brand.id, disabled: !brand.available },
        ]"
        @click="brand.available && selectBrand(brand.id)"
      >
        <div class="brand-content">
          <img :src="brand.image" :alt="brand.brand_name" />
          <div v-if="!brand.available" class="coming-soon">Coming Soon</div>
          <div class="button-wrapper" v-if="brand.available">
            <button
              class="visit-button"
              @click.stop="visitWebsite(brand.website)"
            >
              前往官网
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Search Bar -->
    <div class="search-section">
      <div class="search-container">
        <input
          v-model="url"
          type="text"
          placeholder="Insert your link here..."
          class="search-input"
        />
        <button class="search-button" @click="searchProduct">Search</button>
      </div>
      <p class="search-hint">*请输入所选品牌加拿大官网上的产品链接</p>
    </div>

    <!-- Product Details Modal -->
    <!-- Element UI Dialog for Product Popup -->
    <el-dialog
      :visible.sync="isModalOpen"
      width="60%"
      :close-on-click-modal="false"
      custom-class="product-dialog"
      @close="closeModal"
      center
    >
      <template v-if="product">
        <div class="modal-content">
          <!-- Left section for the product image -->
          <div class="product-image">
            <img :src="product.image" alt="Product Image" />
          </div>

          <!-- Right section for the product info -->
          <div class="product-info">
            <h3>{{ product.brand_name }}</h3>
            <h2>{{ product.product_name }}</h2>
            <p class="price">$30</p>

            <!-- Loop through grouped variants -->
            <p>Colors:</p>
            <div
              v-for="variant in groupedVariants.Color"
              :key="variant.variant_value"
              :class="[
                'variant-circle',
                selectedColor === variant.variant_value ? 'selected' : '',
              ]"
              :style="{
                backgroundImage: `url(${variant.variant_image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }"
              @click="selectColor(variant.variant_value)"
            ></div>
            <p>Size:</p>
            <div class="variant-options">
              <span
                v-for="variant in groupedVariants.Size"
                :key="variant.variant_value"
                :class="[
                  'variant-square',
                  selectedVariants.Size === variant.variant_value
                    ? 'selected'
                    : '',
                  !variant.inStock ? 'out-of-stock' : '',
                ]"
                @click="selectVariant('Size', variant.variant_value)"
              >
                {{ variant.variant_value }}
                <small v-if="!variant.inStock">(Out of Stock)</small>
              </span>
            </div>

            <div class="actions">
              <button class="add-to-cart-button" @click="addToCart">
                Add to Cart
              </button>
              <button class="more-links-button" @click="addMore">
                Add More Links
              </button>
            </div>
          </div>
        </div>
      </template>
    </el-dialog>

    <!-- TODO -->
    <div class="test-section">
      <div class="search-container">
        <router-link to="/cart">
          <button class="test-button">Shopping Cart</button>
        </router-link>
      </div>
    </div>

    <Loader :loaderText="loaderText" :loaderToggle="loaderToggle" />
  </section>
</template>

<script>
import axios from "axios";
import Loader from "./utils/Loader.vue";
// Example usage in BrandList.vue
export default {
  name: "BrandList",
  components: {
    Loader
  },
  data() {
    return {
      url: "", // Store the URL entered by the user
      selectedBrand: 4, // Track the selected brand
      brands: [], // Dynamic list of brands
      product: null, // Store fetched product data
      isModalOpen: false,
      selectedColor: null, // Track the selected color
      selectedVariants: {}, // Store selected variant values
      loaderText: 'Please wait',
      loaderToggle: false,
    };
  },
  computed: {
    groupedVariants() {
      if (!this.product || !this.selectedColor) return {};

      const selectedVariant = this.product.variants.find(
        (variant) => variant.color === this.selectedColor
      );

      if (!selectedVariant) return {};

      return {
        Color: this.product.variants.map((variant) => ({
          variant_type: "Color",
          variant_value: variant.color,
          variant_image: variant.imageUrl,
        })),
        Size: selectedVariant.sizes.map((size) => ({
          variant_type: "Size",
          variant_value: size.size,
          inStock: size.inStock,
        })),
      };
    },
  },
  methods: {
    async fetchBrands() {
      this.loaderToggle = true
      try {
        const response = await axios.get(
          "https://a8ha3zk9l3.execute-api.us-east-1.amazonaws.com/Prod/brands/list"
        );
        this.brands = response.data; // Populate the brands data
        this.loaderToggle = false
      } catch (error) {
        this.loaderToggle = false
        console.error("Error fetching brands:", error);
        alert("Failed to load brands. Please try again later.");
      }
    },
    selectBrand(brandId) {
      this.selectedBrand = brandId; // Set the selected brand ID
    },
    async searchProduct() {
      
      if (!this.$store.getters.isLoggedIn) {
        alert("You need to login to search for products.");
        this.$router.replace("/login");
        return;
      }

      if (!this.url.trim()) {
        alert("Please enter a valid URL.");
        return;
      }

      // Extract `product_id` from the URL
      const match = this.url.match(/prod(\d+)/);
      if (!match) {
        alert("Invalid Lululemon product URL.");
        return;
      }

      // Extract product name
      let productName = this.url.split("/_/")[0].split("/").pop();

      if (productName.includes("-MD")) {
        productName = productName.replace("-MD", "").trim(); // Remove "MD" and trim whitespace
      }

      const productIdMatch = this.url.match(/prod(\d+)/);
      const colorIdMatch = this.url.match(/color=(\d+)/);

      const productId = `prod${match[1]}`;
      const colorId = colorIdMatch[1];

      this.loaderToggle = true
      try {
        // Call the API to fetch variants
        const response = await axios.post(
          "https://a8ha3zk9l3.execute-api.us-east-1.amazonaws.com/Prod/parse/lulu",
          { product_id: productId }
        );

        if (!productIdMatch || !colorIdMatch) {
          alert("Invalid Lululemon product URL.");
          return;
        }

        // Call the new API to fetch "complete the look" data
        const product_response = await axios.post(
          "https://a8ha3zk9l3.execute-api.us-east-1.amazonaws.com/Prod/parse/lulu/middleware", // Replace with your actual Lambda endpoint URL
          {
            method: "GET",
            url: `https://shop.lululemon.com/outfitting/api/v3/complete-the-look`,
            headers: {
              "Content-Type": "application/json",
            },
            params: {
              product_id: productName,
              application: "BF47FEEC539E75BFF0D4",
              product_color_id: colorId,
              return_pdp_item: true,
            },
          }
        );

        const data = product_response.data;

        // Set the main product details
        const mainProduct = data.pdp_item;

        // Transform the response to match the required structure
        this.product = {
          product_name: productName, // Placeholder product name
          brand_name: "Lululemon",
          image: mainProduct.details.original_images[0] || "", // Use the first variant's image as the main product image
          variants: [
            ...response.data.map((variant) => ({
              color: variant.color,
              imageUrl: variant.imageUrl,
              sizes: variant.sizes, // Each color has its own sizes
            })),
          ],
          price: mainProduct.details.list_price,
        };

        this.selectedColor = this.product.variants[0]?.color || null; // Default to the first color
        this.isModalOpen = true;
        this.loaderToggle = false
        console.log("Product data:", this.product);
      } catch (error) {
        this.loaderToggle = false
        console.error("Error fetching product data:", error);
        alert(
          "Failed to fetch product data. Please check the URL and try again."
        );
      }
    },
    closeModal() {
      this.isModalOpen = false;
      this.product = null;
      this.selectedVariants = {}; // Reset selected variants
    },
    visitWebsite(website) {
      window.open(website, "_blank"); // Open the website in a new tab
    },
    selectVariant(variantType, variantValue) {
      // Store the selected variant by type
      this.$set(this.selectedVariants, variantType, variantValue);
    },
    isColorVariant(variant) {
      return variant.variant_type === "Color";
    },
    async addToCart() {
      try {
        const payload = {
          brand_name: this.product.brand_name,
          product_name: this.product.product_name,
          image: this.product.image,
          quantity: 1, // Hardcoded for demonstration
          variants: [
            ...Object.entries(this.selectedVariants).map(
              ([variant_type, variant_value]) => ({
                variant_type,
                variant_value,
              })
            ),
            { variant_type: "Price", variant_value: this.product.price }, // Add price as a fixed variant
          ],
        };

        const response = await axios.post(
          "https://a8ha3zk9l3.execute-api.us-east-1.amazonaws.com/Prod/cart/create",
          payload,
          {
            params: { user_id: "9" },
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$store.state.token}`,
            },
          }
        );

        alert("Added to cart successfully!");
        this.isModalOpen = false;
      } catch (error) {
        console.error("Error adding to cart:", error);
        alert("Failed to add product to cart. Please try again.");
      }
    },
    addMore() {
      this.isModalOpen = false;
    },

    selectColor(color) {
      this.selectedColor = color; // Update the selected color
      this.$set(this.selectedVariants, "Color", color); // Add the selected color to selectedVariants
    },
  },
  mounted() {
    this.fetchBrands(); // Fetch brands on page load
  },
};
</script>

<style scoped>
.brand-list-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-left: 77px;
  background-color: white;
}

.title-section {
  align-self: flex-start;
  margin-bottom: 10px; /* Adjust margin to reduce space below the title */
}

.title-section h2 {
  font-family: "Chunk Five", serif;
  font-size: 18px;
}

.title-section span {
  font-family: "Canva Sans", sans-serif;
  font-size: 12px;
  margin-left: 10px;
}

.brands-container {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 30px;
  gap: 30px;
}

.brand-card {
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #f0f0f0;
  border-radius: 10px;
  transition: transform 0.3s ease, border 0.3s ease; /* Smooth transition for scaling and border */
  cursor: pointer;
  border: 4px solid transparent; /* Default border transparent */
}

.brand-card.selected {
  border: 4px solid #ffde59; /* Selected brand border */
  transform: scale(1.2); /* Enlarge selected brand */
}

.brand-card:hover {
  transform: scale(1.15); /* Hover effect */
}

.brand-card img {
  width: 100%;
  height: 100%;

  object-fit: contain;
}

.button-wrapper {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%; /* Ensure the button wrapper spans the width */
  display: flex;
  justify-content: center;
}

.brand-card .visit-button {
  background-color: #ff6b3b;
  color: white;
  padding: 5px 15px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap; /* Prevent text from wrapping into two lines */
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Search Section */
.search-section {
  width: 100%;
  height: 100px;
  display: flex;
  margin-top: 55px;
  flex-direction: column;
  justify-content: center;
}

.search-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 90%;
}

.search-input {
  width: 100%;
  padding: 10px 40px;
  height: 60px;
  font-size: 18px;
  border-radius: 50px;
  border: 1px solid #ddd;
  text-align: left;
  background-color: #efefef;
  color: black;
  padding-right: 90px;
}

.search-input::placeholder {
  font-family: "Chunk Five", sans-serif;
  color: black;
  position: relative;
}

.search-button {
  font-family: "Chunk Five", sans-serif;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: black;
  color: #ffde59;
  padding: 10px 30px;
  border: none;
  border-radius: 25px;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  height: 50px;
  width: 150px;
}

.search-hint {
  font-family: "Canva Sans", sans-serif;
  font-size: 14px;
  color: #777;
  margin-top: 5px;
  text-align: left;
  margin-left: 40px;
}

/* TODO */
.test-section {
  width: 100%;
  height: 100px;
  display: flex;
  margin-top: 55px;
  flex-direction: column;
  justify-content: center;
}

.test-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 90%;
}

.test-button {
  font-family: "Chunk Five", sans-serif;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: black;
  color: #ffde59;
  padding: 10px 30px;
  border: none;
  border-radius: 25px;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  height: 50px;
  width: 300px;
}
/* Modal Styles */

::v-deep .el-dialog__header {
  border-radius: 25px 25px 0 0;
  letter-spacing: 2px;
}

::v-deep .el-dialog__footer {
  border-radius: 0 0 25px 25px;
}

::v-deep .el-dialog {
  border-radius: 25px;
  height: 70vh;
  overflow: auto;
}

.modal-content {
  display: flex;
}

.product-image {
  margin-left: 30px;
  background-color: #f5f5f5;
  border-radius: 10px;
  width: 40%;
  object-fit: contain;
}

.product-image img {
  width: 100%;
  height: 100%;
}

.product-info {
  flex: 2;
  margin-left: 40px;
}

.product-info h2 {
  font-size: 24px;
  color: #302b2b;
  margin: 0;
}

.product-info h3 {
  font-size: 12px;
  color: #302b2b;
}

.price {
  font-size: 12px;
  color: #302b2b;
}

.variant-section {
  margin-top: 20px;
  color: #302b2b;
}

/* Variant Sections */
.variant-section {
  margin-top: 20px;
}

.variant-options {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

/* Color Variant Styling as Circle */
.variant-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  border: 2px solid #ddd;
  display: inline-block;
  background-color: transparent;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}

.variant-circle.selected {
  border: 2px solid black;
  box-shadow: 0 0 0 6px white;
}

.variant-square {
  padding: 8px 12px;
  background-color: #f0f0f0;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.variant-square.selected {
  background-color: black;
  color: #ffde59;
}

.variant-square.out-of-stock {
  background-color: #ccc;
  cursor: not-allowed;
  color: #666;
}

/* Actions */
.actions {
  display: flex;
  gap: 10px;
  margin-top: 40px;
}

.add-to-cart-button {
  background-color: #ffde59;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
}

.more-links-button {
  background-color: #333;
  color: #ffde59;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
}

.brand-card.disabled {
  pointer-events: none; /* Disable clicks */
  opacity: 0.5; /* Make it look inactive */
  position: relative;
}

.coming-soon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 16px;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
}

</style>
