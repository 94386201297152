<template>
  <div class="purchases">
    <h3>Purchases</h3>

    <!-- Order List -->
    <div class="order-card" v-for="order in orders" :key="order.order_number">
      <div class="order-info">
        <p><strong>Order No.</strong></p>
        <p>
          <router-link
            :to="`/account-details/purchases/${order.order_number}`"
            class="order-number-link"
          >
            {{ order.order_number }}
          </router-link>
        </p>

        <p>{{ order.date }}</p>
        <p style="margin-top: 10px">
          <strong>Total ${{ order.total }}</strong>
        </p>
      </div>
      <div class="order-status" :class="order.statusClass">
        {{ order.status }}
      </div>
    </div>
    <Loader :loaderText="loaderText" :loaderToggle="loaderToggle" />
  </div>
  
</template>

<script>
import axios from "axios";
import Loader from "../utils/Loader.vue";
export default {
  name: "Purchases",
  components: {
    Loader
  },
  data() {
    return {
      orders: [], // Orders data fetched from the API
      loaderText: 'Please wait',
      loaderToggle: false,
    };
  },
  methods: {
    async fetchOrders() {
      this.loaderToggle = true
      const apiUrl =
        "https://a8ha3zk9l3.execute-api.us-east-1.amazonaws.com/Prod/order/list";
      try {
        // Fetch the orders from the API
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.state.token}`, // Replace with your method to fetch token
          },
        });

        // Transform the response to match the structure needed for display
        this.orders = response.data.map((order) => ({
          order_number: order.order_number,
          date: new Date().toLocaleDateString(), // Mock date
          total: 150, // Hardcoded total
          status: order.status,
          statusClass: this.getStatusClass(order.status),
        }));
        this.loaderToggle = false
      } catch (error) {
        console.error("Error fetching orders:", error);
        if (error.status == 403) {
          alert("You need to login to search for products.");
          this.$router.replace("/login");
        }
        this.loaderToggle = false
        alert("Failed to fetch orders. Please try again later.");
      }
    },
    getStatusClass(status) {
      // Map status to CSS classes
      switch (status) {
        case "pending":
          return "pending";
        case "ready_to_ship":
          return "ready-to-ship";
        case "confirmed":
          return "confirmed";
        case "completed":
          return "completed";
        default:
          return "";
      }
    },
  },
  mounted() {
    this.fetchOrders(); // Fetch orders on component mount
  },
};
</script>

<style scoped>
.purchases {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

h3 {
  font-family: "Chunk Five", serif;
  font-size: 24px;
  margin-bottom: 30px;
}

.order-card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 30px;
  width: 80%;
  margin-bottom: 30px;
  border-radius: 10px; /* Rounded corners */
  border: 2px solid #efefef; /* Change this color to your desired border color */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative; /* Needed for absolute positioning of the status */
}

.order-info {
  font-family: "Canva Sans", sans-serif;
  font-size: 16px;
}

.order-info p {
  margin: 5px 0;
}

.order-number-link {
  color: #00bf63;
  text-decoration: underline; /* Make it look like a hyperlink */
  cursor: pointer; /* Change the cursor to pointer on hover */
}

.order-status {
  position: absolute; /* To position the status in the top-right */
  top: 20px;
  right: 30px;
  font-family: "Canva Sans", sans-serif;
  font-weight: bold;
  font-size: 16px;
}

/* Status Colors */
.pending {
  color: grey;
}

.ready-to-ship {
  color: #00bf63;
}

.confirmed {
  color: #ff914d;
}

.completed {
  color: lightcoral;
}
</style>
