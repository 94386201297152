<template>
  <div id="app" data-app>
    <Navbar />
    <router-view class="main-content"></router-view>
    <Footer />
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import HomePage from './components/HomePage.vue';
import Footer from './components/Footer.vue';

export default {
  name:"App",
  components: {
    Navbar,
    HomePage,
    Footer
  },
  data: () => ({
    //
  }),
};
</script>

<style>
/* Global styles */
/* Global font style for the body */
body {
  font-family: 'Canva Sans', sans-serif;
}

/* Specific style for "Borderless." and "One Cart, Limitless Brands" */
h1, .brand-title {
  font-family: 'Chunk Five', serif;
  font-size: 56px; /* Set the size for these headings */
  font-weight: normal;
  line-height: 1.2; /* Adjust line height if necessary */
}

/* Style for the paragraph */
p {
  font-family: 'Canva Sans', sans-serif;
  font-size: 16px; /* Adjust based on design */
  line-height: 1.5;
}

/* Button and other elements can use similar styles */
button {
  font-weight: bold;
  background-color: black;
  color: yellow;
  padding: 10px;
  border: none;
  cursor: pointer;
}
/* If you want to make some text bold or adjust weight */
h1, h2, h3, h4, h5, h6 {
  font-weight: 400; /* Bold for headings */
}

p, span, a {
  font-weight: 400; /* Regular weight for body text */
}


#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1; /* Make main content fill the remaining space */


}

Footer {
  background-color: black;
  color: yellow;
  padding: 10px;
}


</style>
