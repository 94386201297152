import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('authToken') || null,
    isLoading: false,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      if (token) {
        localStorage.setItem('authToken', token);
      } else {
        localStorage.removeItem('authToken');
      }
    },
    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
  actions: {
    async login({ commit }, { email, password }) {
      commit('setLoading', true);
      try {
        const response = await axios.post(
          'https://a8ha3zk9l3.execute-api.us-east-1.amazonaws.com/Prod/user/login',
          { email, password },
          { headers: { 'Content-Type': 'application/json' } }
        );
        const token = response.data.token;
        commit('setToken', token);
        return true;
      } catch (error) {
        console.error('Login error:', error);
        return false;
      } finally {
        commit('setLoading', false);
      }
    },
    logout({ commit }) {
      commit('setToken', null);
    },
  },
  getters: {
    isLoggedIn: (state) => {
      if (!state.token) return false;

      try {
        const { exp } = jwtDecode(state.token); // Decode the token to get the `exp` field
        const currentTime = Math.floor(Date.now() / 1000); // Get the current time in seconds
        return exp > currentTime; // Check if the token is still valid
      } catch (error) {
        console.error('Error decoding token:', error);
        return false;
      }
    },
    isLoading: (state) => state.isLoading,
  },
});
