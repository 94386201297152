<template>
  <section class="login-page">
    <div class="login-container">
      <div class="sign-in-section">
        <h1>Create Account</h1>
        <div class="name-section">
          <input
            v-model="first_name"
            type="text"
            placeholder="First Name"
            class="input-field"
            style="margin-right: 30px;"
          />
          <input
            v-model="last_name"
            type="text"
            placeholder="Last Name"
            class="input-field"
          />
        </div>

        <input
          v-model="email"
          type="email"
          placeholder="E-mail"
          class="input-field"
        />

        <input
          v-model="password"
          @input="validatePassword"
          type="password"
          placeholder="Password"
          class="input-field"
        />

        <ul class="password-requirements">
          <li :class="{ valid: passwordConditions.length }">
            Must be at least 8 characters
          </li>
          <li :class="{ valid: passwordConditions.number }">
            Must include at least 1 number
          </li>
          <li :class="{ valid: passwordConditions.letter }">
            Must include at least 1 letter
          </li>
        </ul>

        <button
          @click="handleRegister"
          class="register-button"
          :disabled="!isFormValid || isLoading"
        >
          {{ isLoading ? "Registering..." : "Create Account" }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "Register",
  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      passwordConditions: {
        length: false,
        number: false,
        letter: false,
      },
      isLoading: false, // Tracks loading state
    };
  },
  computed: {
    isFormValid() {
      return (
        this.first_name &&
        this.last_name &&
        this.email &&
        this.passwordConditions.length &&
        this.passwordConditions.number &&
        this.passwordConditions.letter
      );
    },
  },
  methods: {
    validatePassword() {
      this.passwordConditions.length = this.password.length >= 8;
      this.passwordConditions.number = /\d/.test(this.password);
      this.passwordConditions.letter = /[a-zA-Z]/.test(this.password);
    },

    async handleRegister() {
      if (!this.isFormValid) {
        alert("Please complete the form correctly.");
        return;
      }

      this.isLoading = true;

      try {
        // Prepare the payload
        const payload = {
          email: this.email,
          password: this.password,
          first_name: this.first_name,
          last_name: this.last_name,
        };

        // Send the POST request to the register API
        const response = await axios.post(
          "https://a8ha3zk9l3.execute-api.us-east-1.amazonaws.com/Prod/user/register",
          payload,
          {
            headers: { "Content-Type": "application/json" },
          }
        );

        // Handle success
        alert("Registration successful!");
        console.log("API Response:", response.data);

        // Optionally redirect to login page after success
        this.$router.push("/login");
      } catch (error) {
        // Handle error
        console.error("Registration error:", error.response || error.message);
        alert(
          error.response?.data?.message ||
            "Registration failed. Please try again."
        );
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style scoped>
.login-page {
  display: flex;
  padding-top: 60px;
  justify-content: center;
  background-color: white;
}

.login-container {
  width: 100%;
  max-width: 600px;
}

.sign-in-section {
  width: 100%;
}

.name-section {
  display: flex;
}

h1 {
  font-family: "Chunk Five", serif;
  font-size: 28px;
  margin-bottom: 20px;
}

.input-field {
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  font-size: 16px;
  border-radius: 10px;
  border: 1px solid #ddd;
  background-color: #f0f0f0;
}

.password-requirements {
  margin: 10px 0;
  padding: 0;
  list-style: none;
}

.password-requirements li {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  color: black;
}

.password-requirements li::before {
  content: "●"; /* Adds a little circle */
  font-size: 10px;
  margin-right: 8px;
  color: black; /* Default color for invalid */
}

.password-requirements li.valid {
  color: green; /* Change text color for valid condition */
}

.password-requirements li.valid::before {
  color: green; /* Change circle color for valid condition */
}

.register-button {
  width: 100%;
  padding: 15px;
  background-color: orange;
  color: black;
  font-family: "Chunk Five", serif;
  font-size: 18px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.register-button:disabled {
  cursor: not-allowed;
  background-color: #cccccc; /* Disabled button color */
}
</style>
  