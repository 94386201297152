import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/global.css';
import router from './router'; // Import the router
import store from './store'; // Import Vuex store
import vuetify from './plugins/vuetify'
import 'vuetify/dist/vuetify.min.css'; // For Vuetify 2.x

Vue.use(ElementUI);
Vue.config.productionTip = false

// Mount the Vue instance and inject the router
new Vue({
  // Add the router here
  router,

  // Register Vuex store here
  store,

  vuetify,
  render: h => h(App)
}).$mount('#app');
