<template>
  <div class="address-book">
    <h3 v-if="!isAddingAddress">Address Book</h3>
    <!-- Address Form -->
    <AddressForm
      v-if="isAddingAddress"
      :isEditing="false"
      @cancel="handleCancel"
      @addressSaved="handleAddressSaved"
    />

    <!-- Address Cards -->
    <div v-if="!isAddingAddress" class="address-grid">
      <div
        class="address-card"
        v-for="address in addresses"
        :key="address.id"
        :class="{ active: address.is_default }"
      >
        <div class="address-info">
          <p><strong>{{ address.recipient_name }}</strong></p>
          <p style="margin-top:20px">{{ address.address }}</p>
          <p>{{ address.address_extra }}</p>
          <p>{{ address.city }}, {{ address.state }} {{ address.zipcode }}</p>
          <p>{{ address.country }}</p>
          <p>+{{ address.area_code }} {{ address.phone }}</p>
        </div>
        <div class="actions">
          <a href="#" class="delete" @click.prevent="deleteAddress(address.id)"
            >Delete</a
          >
        </div>
      </div>

      <!-- Add Address Button -->
      <div class="add-address" @click.prevent="addAddress">
        <a href="#" >+ Add Address</a>
      </div>
    </div>
    <Loader :loaderText="loaderText" :loaderToggle="loaderToggle" />
  </div>
</template>

<script>
import axios from "axios";
import AddressForm from "./AddressForm.vue"; // Import the AddressForm component
import Loader from "../utils/Loader.vue";

export default {
  name: "AddressBook",
  components: {
    AddressForm,
    Loader
  },
  data() {
    return {
      addresses: [], // Addresses data fetched from the API
      isAddingAddress: false, // Track whether the form is visible
      loaderText: 'Please wait',
      loaderToggle: false,
    };
  },
  methods: {
    async fetchAddresses() {
      this.loaderToggle = true
      const apiUrl =
        "https://a8ha3zk9l3.execute-api.us-east-1.amazonaws.com/Prod/addresses/list";
      try {
        // Fetch the addresses from the API
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.state.token}`, // Replace with your method to fetch token
          },
        });

        // Assign the fetched addresses to the data property
        this.addresses = response.data;
        this.loaderToggle = false
      } catch (error) {
        console.error("Error fetching addresses:", error);
        this.loaderToggle = false
        alert("Failed to fetch addresses. Please try again later.");
      }
    },
    async deleteAddress(id) {
      this.loaderToggle = true
      const apiUrl = `https://a8ha3zk9l3.execute-api.us-east-1.amazonaws.com/Prod/addresses/del/${id}`;
      try {
        // Call the delete API
        await axios.delete(apiUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.state.token}`, // Replace with your method to fetch token
          },
        });

        // Remove the deleted address from the local list
        this.addresses = this.addresses.filter((address) => address.id !== id);
        this.loaderToggle = false
        alert("Address deleted successfully.");
      } catch (error) {
        console.error("Error deleting address:", error);
        this.loaderToggle = false
        alert("Failed to delete the address. Please try again later.");
      }
    },
    addAddress() {
      // Show the form for adding a new address
      this.isAddingAddress = true;
    },
    handleCancel() {
      // Hide the form and return to the address book
      this.isAddingAddress = false;
      this.fetchAddresses();
    },
    handleAddressSaved(newAddress) {
      // Add the new address to the list and hide the form
      this.isAddingAddress = false;
      this.fetchAddresses();
    },
  },
  mounted() {
    this.fetchAddresses(); // Fetch addresses on component mount
  },
};
</script>


<style scoped>
.address-book {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

h3 {
  font-family: "Chunk Five", serif;
  font-size: 24px;
  margin-bottom: 30px;
}

.address-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
}

.address-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 15px;
  width: 300px; /* Set fixed width for two columns */
  height: 250px; /* Set fixed height for uniformity */
  margin-bottom: 20px;
  border-radius: 10px;
  border: 2px solid #efefef;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.address-card.active {
  border: 2px solid #00bf63;
}

.address-info {
  font-family: "Canva Sans", sans-serif;
  font-size: 16px;
}

.address-info p {
  margin: 5px 0;
}

.actions {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.actions a {
  text-decoration: none;
  font-weight: bold;
  color: #00bf63;
  cursor: pointer;
  transition: color 0.2s;
}

.actions a:hover {
  text-decoration: underline;
}

.add-address {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #ddd;
  border-radius: 10px;
  padding: 20px;
  width: 300px; /* Match the width of address cards */
  height: 200px; /* Match the height of address cards */
  box-sizing: border-box;
  background-color: #fafafa;
  cursor: pointer;
}
.add-address:hover {
  background-color: #f1f1f1;
}

.add-address a {
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}
</style>
