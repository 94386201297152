<template>
  <div class="details">
    <h3>Account Details</h3>
    <div class="detail-item">
      <p class="label"><strong>Account No.</strong></p>
      <p class="value">{{ user.id }}</p>
    </div>
    <div class="detail-item">
      <p class="label"><strong>Account Name.</strong></p>
      <p class="value">{{ user.first_name }} {{ user.last_name }}</p>
    </div>
    <div class="detail-item">
      <p class="label"><strong>Phone.</strong></p>
      <p class="value">+{{ area_code }} {{ phone }}</p> <!-- Placeholder phone -->
    </div>
    <div class="detail-item">
      <p class="label"><strong>E-mail.</strong></p>
      <p class="value">{{ user.email }}</p>
    </div>
    <div class="detail-item">
      <p class="label"><strong>Primary Address.</strong></p>
      <p class="value">{{ primaryAddress }}</p>
    </div>
  </div>
</template>

  
<script>
import axios from "axios";

export default {
  name: "AccountInfo",
  data() {
    return {
      user: {}, // To store user information
      primaryAddress: "Loading...", // Default value until the API call resolves
      area_code:1,
      phone: "",
    };
  },
  methods: {
    async fetchUserDetails() {
      try {
        const userResponse = await axios.get(
          "https://a8ha3zk9l3.execute-api.us-east-1.amazonaws.com/Prod/user",
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.token}`, // Replace with your token management
            },
          }
        );

        this.user = userResponse.data[0]; // Assign the first user data
      } catch (error) {
        console.error("Error fetching user details:", error);
        alert("Failed to fetch user details. Please try again.");
      }
    },
    async fetchPrimaryAddress() {
      try {
        const addressResponse = await axios.get(
          "https://a8ha3zk9l3.execute-api.us-east-1.amazonaws.com/Prod/addresses/list",
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.token}`, // Replace with your token management
            },
          }
        );

        // Find the default address from the list
        const defaultAddress = addressResponse.data.find(
          (address) => address.is_default === 1
        );

        if (defaultAddress) {
          this.primaryAddress = `${defaultAddress.address}, ${defaultAddress.city}, ${defaultAddress.state} ${defaultAddress.zipcode}, ${defaultAddress.country}`;
          this.area_code = defaultAddress.area_code;
          this.phone = defaultAddress.phone;
        } else {
          this.primaryAddress = "No default address found.";
          this.area_code = "";
          this.phone = "No phone found.";
        }
      } catch (error) {
        console.error("Error fetching primary address:", error);
        alert("Failed to fetch primary address. Please try again.");
      }
    },
  },
  mounted() {
    // Fetch user details and primary address when the component is mounted
    this.fetchUserDetails();
    this.fetchPrimaryAddress();
  },
};
</script>
<style scoped>
.details {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-family: "Canva Sans", sans-serif;
  color: #333;
}

h3 {
  font-family: "Chunk Five", serif;
  font-size: 24px;
  margin-bottom: 20px;
}

.detail-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.label {
  width: 200px; /* Label width */
  font-weight: bold;
}

.value {
  flex: 1;
  text-align: left;
}
</style>
