<template>
  <section class="login-page">
    <!-- Sign in Section -->
    <div class="login-container">
      <div class="sign-in-section">
        <h1 style="font-size: 24px">Sign in</h1>
        <input
          v-model="email"
          type="text"
          placeholder="Email Address"
          class="input-field"
        />
        <input
          v-model="password"
          type="password"
          placeholder="Password"
          class="input-field"
        />
        <div class="options-container">
          <div class="keep-signed-in">
            <input type="checkbox" id="keep-signed-in" v-model="keepSignedIn" />
            <label for="keep-signed-in">Keep me signed in</label>
          </div>
          <a href="#" class="forgot-password">Forgot Password?</a>
        </div>

        <!-- Show loading spinner or text while logging in -->
        <button @click="handleLogin" class="sign-in-button" :disabled="isLoading">
          <span v-if="!isLoading">Sign In</span>
          <span v-else>Logging in...</span>
        </button>
      </div>

      <!-- New Customer Section -->
      <div class="new-customer-section">
        <h2 style="font-size: 24px">New Customer</h2>
        <button @click="register" class="create-account-button">
          Create Account
        </button>
      </div>
    </div>
    <Loader :loaderText="loaderText" :loaderToggle="loaderToggle" />
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "./utils/Loader.vue";

export default {
  name: "Login",
  components: {
    Loader
  },
  data() {
    return {
      email: "",
      password: "",
      keepSignedIn: false, // Track if the user wants to be kept signed in
      loaderText: 'Please wait',
      loaderToggle: false,
    };
  },
  computed: {
    ...mapGetters(["isLoading"]), // Get loading state from Vuex
  },
  methods: {
    ...mapActions(["login"]), // Map the login action from Vuex

    async handleLogin() { // Avoid calling the method directly as "login"
      this.loaderToggle = true
      const isSuccess = await this.login({
        email: this.email,
        password: this.password,
      });

      if (isSuccess) {
        // Store token in localStorage or sessionStorage based on keepSignedIn
        const token = this.$store.state.token;
        if (this.keepSignedIn) {
          localStorage.setItem("authToken", token);
        } else {
          sessionStorage.setItem("authToken", token);
        }

        alert("Login successful!");
        this.loaderToggle = false
        this.$router.replace("/home"); // Redirect to home page after login
      } else {
        alert("Login failed. Please check your credentials.");
        this.loaderToggle = false
      }
    },

    register() {
      this.$router.replace("/register"); // Navigate to register page
    },
  },
};
</script>

<style scoped>
.login-page {
  display: flex;
  padding-top: 60px;
  padding-left: 10%;
  height: 100vh;
  width: 100%;
  background-color: white;
}

.login-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 900px;
}

.sign-in-section {
  width: 100%;
}

.new-customer-section {
  display: flex;
  flex-direction: column;
  margin-left: 180px;
  width: 65%;
}

h1 {
  font-family: "Chunk Five", serif;
  font-size: 28px;
  margin-bottom: 20px;
}

h2 {
  font-family: "Chunk Five", serif;
  font-size: 24px;
}

.input-field {
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  font-size: 16px;
  border-radius: 10px;
  border: 1px solid #ddd;
  background-color: #f0f0f0;
}

.options-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 15px;
}

.keep-signed-in label {
  color: green;
  font-size: 14px;
}

.forgot-password {
  color: orange;
  font-size: 14px;
  text-decoration: none;
}

.sign-in-button {
  width: 100%;
  padding: 15px;
  background-color: yellow;
  color: black;
  font-family: "Chunk Five", serif;
  font-size: 18px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 80px;
}

.sign-in-button:disabled {
  cursor: not-allowed;
  background-color: #cccccc; /* Disable button color */
}

.create-account-button {
  padding: 15px 40px;
  margin-top: 80px;
  background-color: orange;
  color: black;
  font-family: "Chunk Five", serif;
  font-size: 18px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  height: 60px;
  width: 100%;
}
</style>
