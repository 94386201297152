<template>
  <div class="address-form">
    <h3>Add Address</h3>
    <form @submit.prevent="saveAddress">
      <div class="form-row">
        <input
          v-model="formData.first_name"
          type="text"
          placeholder="First Name"
          required
        />
        <input
          v-model="formData.last_name"
          type="text"
          placeholder="Last Name"
          required
        />
      </div>
      <div class="form-row">
        <input
          v-model="formData.address"
          type="text"
          placeholder="Street Address"
          required
        />
      </div>
      <div class="form-row">
        <input
          v-model="formData.address_extra"
          type="text"
          placeholder="Apt/Suite (Optional)"
        />
      </div>
      <div class="form-row">
        <input
          v-model="formData.city"
          type="text"
          placeholder="City"
          required
        />
        <input
          v-model="formData.state"
          type="text"
          placeholder="State/Province"
          required
        />
      </div>
      <div class="form-row">
        <input
          v-model="formData.country"
          type="text"
          placeholder="Country"
          required
        />
        <input
          v-model="formData.zipcode"
          type="text"
          placeholder="Zip/Postal Code"
          required
        />
      </div>

      <div class="form-row">
        <el-select
          style="margin-top: 10px;"
          v-model="formData.area_code"
          placeholder="Select Area Code"
        >
          <el-option
            v-for="item in areaCodeOptions"
            :key="item.label"
            :label="item.label"
            :value="parseInt(item.value)"
          />
        </el-select>
        <input
          v-model="formData.phone"
          type="text"
          placeholder="Phone Number"
          required
        />
      </div>
      <label class="default-shipping">
        <el-switch
          v-model="formData.is_default"
          style="zoom: 1.5; margin-right: 10px"
          active-color="#13ce66"
          inactive-color="#9d9d9d"
        >
        </el-switch>
        Default Shipping Address
      </label>
      <div class="form-actions">
        <button class="save-btn" type="submit">Save</button>
        <button class="cancel-btn" type="button" @click="cancel">Cancel</button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import areaCodes from "@/assets/formatted_area_codes.json"; // Import the JSON file


export default {
  name: "AddressForm",
  props: {
    isEditing: Boolean, // Indicate if editing an existing address
  },
  data() {
    return {
      formData: {
        first_name: "",
        last_name: "",
        address: "",
        address_extra: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
        is_default: false,
        area_code: 1,
        phone: "",
      },
      areaCodeOptions: areaCodes, // Use the imported JSON data
    };
  },
  methods: {
    async saveAddress() {
      const apiUrl =
        "https://a8ha3zk9l3.execute-api.us-east-1.amazonaws.com/Prod/addresses";
      try {
        const dataToSend = {
          ...this.formData,
          recipient_name: `${this.formData.first_name} ${this.formData.last_name}`,
        };

        const response = await axios.post(apiUrl, dataToSend, {
          headers: {
            Authorization: `Bearer ${this.$store.state.token}`, // Replace with your method to fetch token
          },
        });

        this.$emit("addressSaved", response.data); // Notify parent of the new address
      } catch (error) {
        console.error("Error saving address:", error);
        alert("Failed to save the address. Please try again later.");
      }
    },
    cancel() {
      this.$emit("cancel"); // Notify parent to hide the form
    },
  },
};
</script>

<style scoped>
.address-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align the content at the top */
  align-items: flex-start; /* Align the content to the left */
  width: 100%;
  height: 100%;
  padding: 40px;
  background-color: #ffffff;
  font-family: "Canva Sans", sans-serif;
}

h3 {
  font-family: "Chunk Five", serif;
  font-size: 28px;
  margin-bottom: 30px;
  align-self: flex-start; /* Move the heading to the top-left */
}

form {
  display: flex;
  flex-direction: column;
  width: 100%; /* Full width of the container */
  max-width: 800px; /* Optional: Limit the maximum width */
}

.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 20px; /* Increase spacing between rows */
  width: 100%;
}

input {
  flex: 1;
  padding: 20px; /* Increase padding for larger input */
  font-size: 18px; /* Larger font size for readability */
  border: 1px solid #ddd;
  border-radius: 12px; /* Rounded corners */
  background-color: #f7f7f7;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: all 0.3s ease-in-out;
}

input:focus {
  outline: none;
  border-color: #ffc107;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(255, 193, 7, 0.4); /* Highlight shadow */
}

label.default-shipping {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.save-btn,
.cancel-btn {
  padding: 15px 40px; /* Increase button size */
  font-size: 18px; /* Larger font size for better visibility */
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-weight: bold;
  flex: 1;
  margin: 0 10px;
  text-align: center;
}

.save-btn {
  background-color: #ffc107;
}

.cancel-btn {
  background-color: #e0e0e0;
}

.save-btn:hover {
  background-color: #e6b305;
}

.cancel-btn:hover {
  background-color: #d6d6d6;
}
</style>
