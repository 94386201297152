var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"brand-list-page"},[_vm._m(0),_c('div',{staticClass:"brands-container"},_vm._l((_vm.brands),function(brand){return _c('div',{key:brand.id,class:[
        'brand-card',
        { selected: _vm.selectedBrand === brand.id, disabled: !brand.available },
      ],on:{"click":function($event){brand.available && _vm.selectBrand(brand.id)}}},[_c('div',{staticClass:"brand-content"},[_c('img',{attrs:{"src":brand.image,"alt":brand.brand_name}}),(!brand.available)?_c('div',{staticClass:"coming-soon"},[_vm._v("Coming Soon")]):_vm._e(),(brand.available)?_c('div',{staticClass:"button-wrapper"},[_c('button',{staticClass:"visit-button",on:{"click":function($event){$event.stopPropagation();return _vm.visitWebsite(brand.website)}}},[_vm._v(" 前往官网 ")])]):_vm._e()])])}),0),_c('div',{staticClass:"search-section"},[_c('div',{staticClass:"search-container"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.url),expression:"url"}],staticClass:"search-input",attrs:{"type":"text","placeholder":"Insert your link here..."},domProps:{"value":(_vm.url)},on:{"input":function($event){if($event.target.composing)return;_vm.url=$event.target.value}}}),_c('button',{staticClass:"search-button",on:{"click":_vm.searchProduct}},[_vm._v("Search")])]),_c('p',{staticClass:"search-hint"},[_vm._v("*请输入所选品牌加拿大官网上的产品链接")])]),_c('el-dialog',{attrs:{"visible":_vm.isModalOpen,"width":"60%","close-on-click-modal":false,"custom-class":"product-dialog","center":""},on:{"update:visible":function($event){_vm.isModalOpen=$event},"close":_vm.closeModal}},[(_vm.product)?[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"product-image"},[_c('img',{attrs:{"src":_vm.product.image,"alt":"Product Image"}})]),_c('div',{staticClass:"product-info"},[_c('h3',[_vm._v(_vm._s(_vm.product.brand_name))]),_c('h2',[_vm._v(_vm._s(_vm.product.product_name))]),_c('p',{staticClass:"price"},[_vm._v("$30")]),_c('p',[_vm._v("Colors:")]),_vm._l((_vm.groupedVariants.Color),function(variant){return _c('div',{key:variant.variant_value,class:[
              'variant-circle',
              _vm.selectedColor === variant.variant_value ? 'selected' : '',
            ],style:({
              backgroundImage: `url(${variant.variant_image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }),on:{"click":function($event){return _vm.selectColor(variant.variant_value)}}})}),_c('p',[_vm._v("Size:")]),_c('div',{staticClass:"variant-options"},_vm._l((_vm.groupedVariants.Size),function(variant){return _c('span',{key:variant.variant_value,class:[
                'variant-square',
                _vm.selectedVariants.Size === variant.variant_value
                  ? 'selected'
                  : '',
                !variant.inStock ? 'out-of-stock' : '',
              ],on:{"click":function($event){return _vm.selectVariant('Size', variant.variant_value)}}},[_vm._v(" "+_vm._s(variant.variant_value)+" "),(!variant.inStock)?_c('small',[_vm._v("(Out of Stock)")]):_vm._e()])}),0),_c('div',{staticClass:"actions"},[_c('button',{staticClass:"add-to-cart-button",on:{"click":_vm.addToCart}},[_vm._v(" Add to Cart ")]),_c('button',{staticClass:"more-links-button",on:{"click":_vm.addMore}},[_vm._v(" Add More Links ")])])],2)])]:_vm._e()],2),_c('div',{staticClass:"test-section"},[_c('div',{staticClass:"search-container"},[_c('router-link',{attrs:{"to":"/cart"}},[_c('button',{staticClass:"test-button"},[_vm._v("Shopping Cart")])])],1)]),_c('Loader',{attrs:{"loaderText":_vm.loaderText,"loaderToggle":_vm.loaderToggle}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-section"},[_c('h2',[_vm._v("Choose your brand... "),_c('span',[_vm._v("请选择品牌")])])])
}]

export { render, staticRenderFns }